<template>
	<div class="mainTem">
		<div class="tableConTable">
			<!-- <div class="buyerHeader"><h3 class="buyerHeaderTit">{{$t('i18nn_eb763bfab5d5a930')}}</h3></div> -->
			<div class="tableConTop">
			  <el-row>
			    <el-col :span="8" class="tableConTopLeft">
			      <h3>{{$t('i18nn_b6a8725d78c586be')}}<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
								<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
							</el-tooltip>
						</h3>
						
			    </el-col>
			    <el-col :span="16" class="tableConTopRig">
			      <!-- <el-button @click="batchDownloadAction($event, null)" type="primary" size="small" icon="el-icon-download">{{$t('i18nn_21b22a8018f3cba2')}}</el-button> -->
			      
			      <!-- <el-button type="warning" size="small" icon="el-icon-delete" @click="CancelOrderAction($event)">{{$t('i18nn_bcef26d98d690aba')}}</el-button> -->
			      
			      <!-- <el-button type="danger" size="small" icon="el-icon-close" @click="CancelPrintingAction($event)">{{$t('i18nn_4ca88dd2e7071844')}}</el-button> -->
			      <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
			    </el-col>
			  </el-row>
			</div>
			<div class="filterCon" style="">
				<!-- <div class="filterBarList">
					<el-menu :default-active="filterData.labelStatus" mode="horizontal" @select="handleSelectStatus">
					  <el-menu-item :index="item.value" v-for="item in selectOption.labelStatusList" :key="item.value">{{item.label}}</el-menu-item>
					</el-menu>
				</div> -->
				<!-- <el-row type="flex" justify="end"> -->
					<!-- <el-col :span="24"> -->
						<ul class="filterConList">
							<li>
								<span>{{ $t('hytxs0000060') }}</span>
								<cusSelFuzzy @changeData="changCus"></cusSelFuzzy>
							</li>
							<li>
								<span>{{ $t('c944a6686d996ab3') }}</span>
								<whNoSelect @changeData="changWhNo"></whNoSelect>
							</li>
							<!-- <li>
								<span>{{ $t('43a3586339251494') }}</span>
								<el-date-picker
									v-model="filterData.daterange"
									size="small"
									type="daterange"
									align="right"
									:clearable="true"
									unlink-panels
									range-separator="-"
									start-placeholder="start date"
									end-placeholder="end date"
									:picker-options="pickerOptions"
									format="yyyy-MM-dd"
									value-format="yyyy-MM-dd"
									@change="initData()"
								></el-date-picker>
							</li> -->
							<!-- <li>
								<span>{{ $t('43a3586339251494') }}</span>
								<el-date-picker v-model="filterData.commitDateArr" type="daterange" align="right"
									unlink-panels range-separator="-"
									:start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
									:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd"
									format="yyyy-MM-dd" size="small" @change="initData()"
									:picker-options="pickerOptions"></el-date-picker>
							</li> -->
						<!-- </ul> -->
						<!-- <ul class="filterConList"> -->
							<!-- <li>
								<span>{{ $t('i18nn_06dd7cb65641390b') }}</span>
								<el-select filterable clearable v-model="filterData.labelReady" :placeholder="$t('2ad108ab2c560530')" size="small" style="width: 100px;" @change="initData()">
									<el-option v-for="item in selectOption.labelReadyList" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</li> -->
							<!-- <li>
								<span>{{ $t('i18nn_abf9f4b8c93d641e') }}</span>
								<el-input
									type="text"
									v-model="filterData.orderNumber"
									size="small"
									clearable
									@keyup.enter.native="initData()"
									maxlength="50"
									:placeholder="$t('hytxs0000001')"
									style="width: 180px;"
								/>
								<el-button icon="el-icon-magic-stick" size="small" type="success" plain
									@click="openOrderInputAction()">{{$t('i18nn_68493357463cb368')}}</el-button>
							</li> -->
							<li>
								<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
								<el-input
									type="text"
									v-model="filterData.keyword"
									size="small"
									clearable
									@keyup.enter.native="initData()"
									maxlength="50"
									:placeholder="$t('custom_enterfuzzysearch')"
									style="width: 180px;"
								/>
							</li>
							<li>
								<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">{{ $t('1e7246dd6ccc5539') }}</el-button>
							</li>
						</ul>
						<!-- <ul class="filterConList"
							v-if="filterData.orderNumberList && filterData.orderNumberList.length > 0">
							<li><span>{{$t('i18nn_abf9f4b8c93d641e')}}</span></li>
							<li v-for="(item, index) in filterData.orderNumberList" :key="index">
								<el-tag>{{ item }}</el-tag>
							</li>
							<li>
								<el-button icon="el-icon-close" size="small" type="success" plain
									@click="clearOrderList">{{$t('i18nn_afe446fb3a8e3e40')}}</el-button>
							</li>
						</ul> -->
			</div>
			
			
			<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
				<el-table
					ref="multipleTable"
					:data="tableData"
					stripe
					:border="true"
					style="width: 100%"
					size="small"
					:height="$store.state.tableMaxHeight3"
					@row-click="handleCurrentChange"
					@selection-change="handleSelectionChange"
					v-loading="loading"
				>
					<!-- <el-table-column type="selection" fixed="left" align="center" width="55"></el-table-column> -->
					<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>

					<el-table-column prop="batchNo" :label="$t('i18nn_b458f03c43a90ee9')" width="100">
						<template slot-scope="scope">
							<div>
								<!-- <el-link v-if="scope.row.batchNo" type="danger" size="mini"
									@click="toActionView($event, scope.row, scope.$index)"> -->
									{{ scope.row.batchNo }}
								<!-- </el-link> -->
							</div>
						</template>
					</el-table-column>
					
					<!-- <el-table-column prop="sysDefOrderNum" :label="$t('i18nn_7f6c721b57ed0e7b')" min-width="100">
						<template slot-scope="scope">
							<div>
									{{ scope.row.sysDefOrderNum }}
							</div>
						</template>
					</el-table-column> -->
					
					<el-table-column prop="orderNumber" :label="$t('i18nn_abf9f4b8c93d641e')" width="150">
						<template slot-scope="scope">
							<div>
								<el-link type="primary" size="mini" icon=""
									@click="toDetActionView($event, scope.row, scope.$index)">
									{{ scope.row.orderNumber }}
								</el-link>
								<!-- <span v-else>{{ scope.row.orderNumber }}</span>
								
								<div v-if="scope.row.orderTypeName">
									<el-tag type="success" v-if="10==scope.row.orderType">
										{{ $Utils.i18nKeyText(scope.row,'orderTypeName') }}
									</el-tag>
									<el-tag type="warning" v-else-if="20==scope.row.orderType">
										{{ $Utils.i18nKeyText(scope.row,'orderTypeName') }}
									</el-tag>
									<el-tag type="info" v-else>
										{{ $Utils.i18nKeyText(scope.row,'orderTypeName') }}
									</el-tag>
								</div> -->
							</div>
						</template>
					</el-table-column>
					
					<!-- <el-table-column prop="carrier" :label="$t('i18nn_b0c50b6ff9674173')"></el-table-column>
					<el-table-column prop="tracking" :label="$t('hytxs0000061')"></el-table-column> -->
					
					<el-table-column prop="carrier" :label="$t('i18nn_e63fc078a7baf7c9')" min-width="180">
						<template slot-scope="scope">
							<div>
								<ul>
									<li>
										<span>{{$t('i18nn_b0c50b6ff9674173')}}</span>：
										<span>{{scope.row.carrier}}</span>
									</li>
									<li>
										<span>{{$t('hytxs0000061')}}</span>：
										<span>
											<!-- <el-link v-if="scope.row.tracking" type="primary" size="mini" icon=""
												@click="toExpLgsTrackView($event, scope.row, scope.$index)"> -->
												{{ scope.row.tracking }}
											<!-- </el-link> -->
										</span>
									</li>
									<li>
										<span>{{$t('i18nn_e83443dd6c718cf9')}}</span>：
										<span style="color:#E6A23C;">{{scope.row.derivedStatus}}</span>
									</li>
									<li>
										<span>{{$t('i18nn_41d020489ecacbf0')}}</span>：
										<span style="color:#333333;">{{scope.row.trackDate}}</span>
									</li>
								</ul>
							</div>
						</template>
					</el-table-column>
					
					<el-table-column prop="dayCount" :label="$t('i18nn_8f89632c68d3b9a2')">
						<template slot-scope="scope">
							<div>
								<el-tag type="danger" v-if="scope.row.dayCount>=3">{{scope.row.dayCount}}</el-tag>
								<el-tag type="info" v-else>{{scope.row.dayCount}}</el-tag>
							</div>
						</template>
					</el-table-column>
					
					<!-- <el-table-column prop="isSelfLabel" :label="$t('i18nn_3601b5a98803f4d7')">
						<template slot-scope="scope">
							<div v-if="scope.row.isSelfLabel">
								<el-tag type="warning" v-if="'10' == scope.row.isSelfLabel">{{$t('i18nn_198d8fd115bfc3d9')}}</el-tag>
								<el-tag type="success" v-else-if="'20' == scope.row.isSelfLabel">{{$t('i18nn_15bc1e82df3428d8')}}</el-tag>
								<el-tag type="info" v-else>{{ scope.row.isSelfLabel }}</el-tag>
							</div>
						</template>
					</el-table-column> -->
					
					
					<!-- <el-table-column prop="labelStatusPressName" :label="$t('i18nn_90429ac39f4f4634')"></el-table-column> -->
					
					<!-- <el-table-column prop="labelUrl" :label="$t('i18nn_1324a1d91ae3de53')">
						<template slot-scope="scope">
							<div class="over_ellipsis" style="max-width: 100%;">
								<a v-if="scope.row.labelInfoDto" :href="scope.row.labelInfoDto.labelUrl"
									:title="scope.row.labelInfoDto.labelUrl" target="_blank">
									{{ scope.row.labelInfoDto.labelUrl }}
								</a>
							</div>
							<div class="over_ellipsis" style="max-width: 100%;">
								<a v-if="scope.row.labelUrl" :href="scope.row.labelUrl" :title="scope.row.labelUrl"
									target="_blank">{{ scope.row.labelUrl }}</a>
							</div>
						</template>
					</el-table-column> -->
					
					<!-- <el-table-column prop="rate" :label="$t('hytxs0000062')"></el-table-column> -->
					
					<!-- <el-table-column prop="senderAddr" :label="$t('431d0d251ff90f3f')"></el-table-column>
					<el-table-column prop="returnAddr" :label="$t('80b704ca1789ae16')"></el-table-column> -->
					
					<!-- <el-table-column prop="goodsSku" label="SKU">
						<template slot-scope="scope2">
							<div v-if="scope2.row.expressSkuList">
								<div v-for="(item,index) in scope2.row.expressSkuList" :key="index">
									{{item.goodsSku}}
								</div>
							</div>
						</template>
					</el-table-column> -->
					
					<el-table-column prop="serchSku" label="SKU"></el-table-column>
					
					<!-- <el-table-column prop="packingNo" :label="$t('i18nn_599710d641d92957')"></el-table-column> -->
					
					<!-- <el-table-column prop="attachments" :label="$t('f5d43732e3f6cf4d')">
						<template slot-scope="scope">
								<div v-if="scope.row.attachments">
									<el-link type="primary">
										...
										<span>{{ $t('73bbf51487ec69e9') }}</span>
										<span>{{ scope.row.attachments.length }}</span>
										<span>{{ $t('i18nn_7f18cb0ceb569eb0') }}</span>
									</el-link>
								</div>
						</template>
					</el-table-column> -->
					
					<!-- <el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
						<template slot-scope="scope">
							<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
								<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
							</el-popover>
						</template>
					</el-table-column> -->
					
					<!-- <el-table-column prop="statusName" :label="$t('6cdece641436d7ab')">
						<template slot-scope="scope">
							<div class="tag-color-grey" v-if="'0' == scope.row.exprStatus">
								{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}
							</div>
							<div class="tag-color-blue" v-else-if="'10' == scope.row.exprStatus">
								{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}
							</div>
							<div class="tag-color-cyan" v-else-if="'20' == scope.row.exprStatus">
								{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}
							</div>
							<div class="tag-color-green" v-else-if="'30' == scope.row.exprStatus">
								{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}
							</div>
							<div class="tag-color-yellow" v-else-if="'35' == scope.row.exprStatus">
								{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}
							</div>
							<div class="tag-color-orange" v-else-if="'40' == scope.row.exprStatus">
								{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}
							</div>
							<div v-else-if="'50' == scope.row.exprStatus">
								<div class="tag-color-red">{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}</div>
							</div>
							<div class="tag-color-brown" v-else-if="'60' == scope.row.exprStatus">
								{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}
							</div>
							<div class="tag-color-purple" v-else>
								{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}
							</div>
					
						</template>
					</el-table-column> -->
					<el-table-column prop="cusName" :label="$t('hytxs0000060')" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="senderAddr" :label="$t('i18nn_f6283c46ba925ab4')" show-overflow-tooltip></el-table-column>
					
					<el-table-column prop="commitDate" :label="$t('i18nn_0ae081c3b8c4d4a1')" ></el-table-column>
					
					<el-table-column prop="exprStatusTime" :label="$t('i18nn_d99d790ec4383bfb')"
						></el-table-column>
					
					<!-- <el-table-column :label="$t('Storage.tableColumn.operation')" width="120px" fixed="right" align="left">
						<template slot-scope="scope">
							<div>
								<div style="margin-bottom: 10px;" v-if="'20' !== scope.row.labelStatus">
									<el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
								</div>
							</div>
						</template>
					</el-table-column> -->
				</el-table>
			</div>
			<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>
		
		<!-- 订单号输入框 -->
		<!-- <el-dialog :title="$t('i18nn_33bb5e57aba192e1')" append-to-body :close-on-click-modal="false" :visible.sync="dialogOrderInputVisible"
			width="1000px">
			<div style="">
				<h3>可多个查询(一行输入一个订单号)</h3>
				<el-input type="textarea" :autosize="{ minRows: 20 }" v-model="filterData.orderNumberListStr"
					size="small" clearable show-word-limit maxlength="1000" :placeholder="'可多个查询(一行输入一个)'"
					style="width: 100%;vertical-align: middle; " />
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogOrderInputVisible = false">{{ $t('4e9fc68608c60999') }}
				</el-button>
				<el-button type="primary" @click="sureOrderInputAction()">{{$t('i18nn_b5511889be42a3da')}}</el-button>
			</div>
		</el-dialog> -->

		<!-- 发货轨迹查询 -->
		<!-- <whQueryExpLgsTrack :openTime="openTimeExpLgsTrack" :row="ExpLgsTrackRow"></whQueryExpLgsTrack> -->
		
		<!-- excel 导出排序 -->
		<whExcelCustom
			:openTime="excelOpenTime"
			:excelHead="excelHead"
			:excelData="expExcelData"
			:pagination="pagination"
			:excelName="excelName"
			:excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhExpressTrunckNotOnlinePage"
			:expHttpFilter="pageFilterData()"
		></whExcelCustom>
	</div>
</template>
<script>
// import { fileZipAndDownload } from '@/utils/fileZipDownload.js';
// import SelAgentUser from '@/components/Common/SelAgentUser.vue';
import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';
import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
import whExcelCustom from '@/components/WarehouseCenter2/components/whExcelCustom.vue';
// import whQueryExpLgsTrack from '@/components/WarehouseCenter2/components/whQueryExpLgsTrack.vue';
export default {
	components: {
		// SelAgentUser
		whNoSelect,
		cusSelFuzzy,
		whExcelCustom,
		// whQueryExpLgsTrack
	},
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	//   meta: [{ // set meta
	//     name: '互易天下-买家中心-收货账单',
	//     content: '互易天下-厂家共享平台-买家中心-收货账单'
	//   }],
	//   // link: [{                 // set link
	//   //   rel: 'asstes',
	//   //   href: 'https://assets-cdn.github.com/'
	//   // }]
	// },
	// props: ['isDialog'],
	// props: {
	// 	// mobile:"",
	// 	// isSel:{
	// 	//   default: function() {
	// 	//     return false
	// 	//   },
	// 	//   type: Boolean
	// 	// },
	// 	exprId: {
	// 		default: function() {
	// 			return '';
	// 		},
	// 		type: String
	// 	}
	// },
	data() {
		return {
			//excel导出
			excelOpenTime: '',
			excelHead: [],
			expExcelData: [],
			excelName: '',
			excelOption: {},
			// UserInfo: this.$store.getters.getUserInfo,
			// dialogFormVisible: false,
			// dialogFormStatus: 0, //0-新增，1-修改

			//图片放大
			// dialogImgVisible: false,
			// imgUrlBigShow: '',
			openTimeExpLgsTrack:'',
			ExpLgsTrackRow:{},

			pickerOptions: this.$PickerDate.pickerOptions_2(),

			loading: false,

			loading_load: false,
			tableData: [],

			multipleSelection: [],
			
			//订单号输入弹窗
			dialogOrderInputVisible: false,

			//分页数据
			pagination: this.$Utils.defaultPagination(),
			selectOption: {
				// wh_weight_unit: [],
				// wh_vol_unit: [],
				// wh_goods_fee_type: [],
				// wh_size_unit: [],
				// // wh_packing_type:[],
				// wh_car_tms_type: [],
				wh_no: [],
				labelReadyList: [
					{
						value: '',
						label: this.$t('16853bda54120bf1')
					},
					{
						value: '0',
						label: this.$t('i18nn_b44a4a27cac7433e')
					},
					{
						value: '1',
						label: this.$t('i18nn_27372599992ff988')
					}
				],
				// labelStatusList: [
				// 	{
				// 		value: '',
				// 		label: this.$t('16853bda54120bf1')
				// 	},
				// 	{
				// 		value: '10',
				// 		label: this.$t('i18nn_cce168301447d1ba')
				// 	},
				// 	{
				// 		value: '20',
				// 		label: this.$t('i18nn_a0acfaee54f06458')
				// 	},
				// 	{
				// 		value: '60',
				// 		label: this.$t('i18nn_7fa1fea309b0fd8d')
				// 	},
				// 	{
				// 		value: '70',
				// 		label: this.$t('i18nn_bcef26d98d690aba')
				// 	},
				// 	{
				// 		value: '90',
				// 		label: this.$t('i18nn_4ca88dd2e7071844')
				// 	}
				// ]
			},
			//查询，排序方式
			filterData: {
				orderBy: 'id_', //排序字段
				sortAsc: 'desc', //desc降序，asc升序

				// agentUser:'',
				// whNo:'',

				daterange: [],
				// exprStatus: '',
				whNo: '',
				orderNumber: '',
				orderNumberList: [],
				orderNumberListStr: '',
				// cusNo: '',
				userId:'',
				commitDate: '',
				keyword: '',
				labelReady: '',
				labelStatus: '',
				agentUser: ''
				// "accountPeriod":"",
				// packId: "",
				// "billNo":""
				// status: '',
				// putWhNo: '',
				// goodsSku: '',
				// hashCode: '',
				// goodsName: '',
				// declareNameCh: '',
			}
		};
	},
	// watch: {
	// 	exprId: function(newVal, oldVal) {
	// 		console.log('watchKey');
	// 		// if (newVal) {
	// 		// console.log('watch openDateTime HyUpLoadImg.vue');
	// 		this.initData();
	// 		// }
	// 	}
	// },
	activated(){
		// if(!!this.exprId){
		// 	this.filterData.daterange = [];
		// } else {
		// 	this.filterData.daterange = [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime()+3600 * 1000 * 24)];
		// }
		this.initData();
	},
	//创建时
	created() {
		// this.getPageData();
	},
	//编译挂载前
	mounted() {
		// this.getDicData();

		
	},
	methods: {
		initData() {
			this.pagination.current_page = 1;
			// this.currentSel = {};
			this.multipleSelection = [];
			// if (this.exprId) {
			this.tableData = [];
			this.getPageData();
			// }
			// this.getDicData();
		},
		handleSelectStatus(key, keyPath) {
		  // console.log(key, keyPath);
			this.filterData.labelStatus = key;
			this.initData();
		},
		changeAgentUser(val) {
			this.filterData.agentUser = val;
			this.initData();
		},
		changCus(data) {
			console.log('changCus', data);
			this.filterData.userId = data.userId;
			// this.filterData.cusNo = data.cusNo;
			this.initData();
		},
		changWhNo(data) {
			console.log('changWhNo', data);
			this.filterData.whNo = data.code;
			// this.filterData.userId = data.userId;
			this.initData();
		},
		
		//导出 excel
		exportExcel() {
			let columns = [{
					title: this.$t('hytxs0000060'),
					key: 'cusName'
				},
				{
					title: this.$t('c944a6686d996ab3'),
					key: 'senderAddr'
				},
				// {
				// 	title: this.$t('43a3586339251494'),
				// 	key: 'commitDate'
				// },
				{
					title: this.$t('i18nn_b458f03c43a90ee9'),
					key: 'batchNo'
				},
				{
					title: this.$t('i18nn_abf9f4b8c93d641e'),
					key: 'orderNumber'
				},
				{
					title: this.$t('i18nn_b0c50b6ff9674173'),
					key: 'carrier'
				},
				{
					title: this.$t('hytxs0000061'),
					key: 'tracking'
				},
				{
					title: this.$t('i18nn_8f89632c68d3b9a2'),
					key: 'dayCount'
				},
				
				// {
				// 	title: this.$t('hytxs0000062'),
				// 	key: 'rate'
				// },
				// {
				// 	title: 'SKU#数量#重量',
				// 	key: 'sku',
				// 	types: 'custom',
				// 	formatter:function(row){
				// 		return row.expressSkuList.map(item=>{
				// 			return item.goodsSku+"#"+item.quantity+"#"+item.totalWeight;
				// 		}).join(",\r");
				// 	}
				// },
				{
					title: 'SKU',
					key: 'serchSku',
					// types: 'custom',
					// formatter: function(row) {
					// 	return row.expressSkuList
					// 		.map(item => {
					// 			return item.goodsSku;
					// 		})
					// 		.join(',');
					// }
				},
				{
					title: this.$t('i18nn_0ae081c3b8c4d4a1'),
					key: 'commitDate'
				},
				
				// {
				// 	title: this.$t('fc7e87b5dfa6e4b8'),
				// 	key: 'quantity',
				// 	types: 'custom',
				// 	formatter: function(row) {
				// 		return row.expressSkuList
				// 			.map(item => {
				// 				return item.quantity;
				// 			})
				// 			.join(',');
				// 	}
				// },
				// {
				// 	title: this.$t('96f1a4b17e75892d') + '(LB)',
				// 	key: 'totalWeight',
				// 	types: 'custom',
				// 	formatter: function(row) {
				// 		return row.expressSkuList
				// 			.map(item => {
				// 				return item.totalWeight;
				// 			})
				// 			.join(',');
				// 	}
				// },
				// {
				// 	title: this.$t('i18nn_599710d641d92957'),
				// 	key: 'packingNo'
				// },
				// {
				// 	title: this.$t('f5d43732e3f6cf4d'),
				// 	key: 'attachments',
				// 	types: 'custom',
				// 	formatter: function(row) {
				// 		return row.attachments
				// 			.map(item => {
				// 				return item.url;
				// 			})
				// 			.join(',');
				// 	}
				// },
				// {
				// 	title: this.$t('15b3627faddccb1d'),
				// 	key: 'remark'
				// },
				// {
				// 	title: this.$t('i18nn_726c51702f70c486'),
				// 	key: 'createTime'
				// }
			];
			let Data = this.tableData;
		
			// let data_dom = JQ('#ex_table').find(".el-table__body");
			// let obj_key_img = {};
			// // console.log(data_dom.find("tr"));
			// data_dom.find("tr").each((index, domEle)=>{
			// 	// console.log("index",index);
			// 	let imgObj = JQ(domEle).find("td .cell img");
			// 	// console.log("imgObj",imgObj);
			// 	if(imgObj){
			// 		obj_key_img[imgObj.attr("code")] = imgObj.attr("src");
			// 	}
			// })
			// // console.log("obj_key_img",obj_key_img);
			// Data.forEach(item=>{
			// 	item.barcodeimg = obj_key_img[item.whNo + '-' + item.place];
			// })
			this.expExcelData = Data;
			this.excelHead = columns;
			this.excelName = 'ExpressTrunckNotOnlineList';
			this.excelOption = {
				height: 20
			};
			this.excelOpenTime = new Date().getTime();
		
			// excelUtilsNew.exportExcel(columns, Data, 'WhDropShipping', { height: 20 });
		},
		
		toDetActionView(event, row){
			event.stopPropagation();
			this.$router.push({name:"WhDropShipping",query:{orderNumber:row.orderNumber}})
		},
		
		//打开订单的输入框
		// openOrderInputAction() {
		// 	// this.filterData.orderNumberListStr = "";
		// 	this.dialogOrderInputVisible = true;
		// },
		//确定订单的输入框
		// sureOrderInputAction() {
		// 	this.dialogOrderInputVisible = false;
		// 	this.filterData.orderNumber = '';
		// 	let orderNumberList = [];
		// 	if (this.filterData.orderNumberListStr) {
		// 		orderNumberList = this.filterData.orderNumberListStr.split('\n').filter(function(s) {
		// 			return s && s.trim();
		// 		});
		// 	}
		// 	this.filterData.orderNumberList = orderNumberList;
		// 	this.initData();
		// },
		//清除搜索订单号的值
		// clearOrderList() {
		// 	this.filterData.orderNumberListStr = '';
		// 	this.filterData.orderNumberList = [];
		// },
		
		//删除
		delAction(event, row) {
			this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
				
				type: 'warning'
			})
				.then(() => {
					this.delDataAction(event, row);
				})
				.catch(() => {
					
				});
		},
		// //删除
		delDataAction(event, row) {
			event.stopPropagation();
			console.log('delDataAction', row);
		
			this.postData(
				this.$urlConfig.WhThridLabelInfoDetDel + '/' + row.id,
				{},
				() => {
					this.initData();
					this.$message.success(this.$t('i18nn_de017dafc266aa03'));
				},
				'delete'
			);
		},

		//选择行
		handleCurrentChange(row, event, column) {
			// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
		},
		//多选
		handleSelectionChange(val) {
			console.log(val);
			this.multipleSelection = val;
		},
		//查询数据
		serPageData() {
			// this.pagination.current_page = 1;
			this.getPageData();
		},
		//分页的筛选项数据
		pageFilterData() {
			let startTime = '';
			let endTime = '';
			if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
				startTime = this.filterData.daterange[0];
				endTime = this.filterData.daterange[1];
			} else {
			}
			
			let orderNumberList = [];
			
			if (this.filterData.orderNumberListStr) {
				orderNumberList = this.filterData.orderNumberList;
			} else if (this.filterData.orderNumber) {
				orderNumberList = [this.filterData.orderNumber];
			}
		
			return {
				// exprId: this.exprId, //"orde的ID",
				userId: this.filterData.userId ? this.filterData.userId : null,
				orderNumberList: orderNumberList.length > 0 ? orderNumberList : null,
				keyword: this.filterData.keyword ? this.filterData.keyword : null,
				labelReady: this.filterData.labelReady ? this.filterData.labelReady : null,
				labelStatus: this.filterData.labelStatus ? this.filterData.labelStatus : null,
				whNo: this.filterData.whNo ? this.filterData.whNo : null,
				
				createTimeStart: startTime ? startTime : null,
				createTimeEnd: endTime ? endTime : null
			};
		},
		//请求分页数据
		getPageData() {
			let filterData = Object.assign(
				{
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size //当前页显示数目
				},
				this.pageFilterData()
			);

			// let _this = this;
			this.loading_load = true;
			this.$http
				.put(this.$urlConfig.WhExpressTrunckNotOnlinePage, filterData)
				.then(({ data }) => {
					// console.log(this.$store.getters.getUserInfo);
					console.log('分页，请求成功');
					console.log(data);
					
					this.loading_load = false;
					let tableData = data.rows;
					// tableData.forEach(item=>{
					// 	try {
					// 		item.labelRequestObj = JSON.parse(item.labelRequest);
					// 	} catch(err){
					// 		console.log(err);
					// 	}
						
					// });
					//表格显示数据
					this.tableData = tableData;
					//当前数据总条数
					this.pagination.total = parseInt(data.total);
				})
				.catch(error => {
					console.log(error);
					console.log('分页，请求失败');
					this.loading_load = false;
				});
		},
		
		//提交数据
		postData(url, formData, callback, type) {
			// let _this = this;
			this.loading = true;
			let http = {};
			if ('delete' == type) {
				http = this.$http.delete(url, formData);
			} else {
				http = this.$http.put(url, formData);
			}
			http
				.then(({ data }) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					this.loading = false;
					if (200 == data.code) {
						callback(data);
					} else {
						if (!data.msg) {
							data.msg = this.$t('i18nn_a7d2e953195a5588');
						}
						this.$alert(data.msg, this.$t('daaaeb1b7b22b126'), {
							type: 'warning'
						});
					}
				})
				.catch(error => {
					console.log(error);
					console.log(this.$t('i18nn_a7d2e953195a5588'));
					this.loading = false;
					this.$alert(this.$t('i18nn_a7d2e953195a5588'), this.$t('daaaeb1b7b22b126'), {
						type: 'warning'
					});
				});
		}
		
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
